import httpService from "@/request"


// 路线
export function routeList(params) {
	return httpService({
		url: `/user/inspectionRoute/list`,
		method: 'get',
		params: params,
	})
}
export function routeisEnable(params) {
	return httpService({
		url: `/user/inspectionRoute/isEnable`,
		method: 'get',
		params: params,
	})
}
export function pointSelect(params) {
	return httpService({
		url: `/user/inspectionPoint/allList`,
		method: 'get',
		params: params,
	})
}
export function routeInfo(params) {
	return httpService({
		url: `/user/inspectionRoute/findById`,
		method: 'get',
		params: params,
	})
}
export function routeInsert(params) {
	return httpService({
		url: `/user/inspectionRoute/insert`,
		method: 'post',
		data: params,
	})
}
export function routeDel(params) {
	return httpService({
		url: `/user/inspectionRoute/delete`,
		method: 'post',
		data: params,
	})
}
export function routeUpdate(params) {
	return httpService({
		url: `/user/inspectionRoute/update`,
		method: 'post',
		data: params,
	})
}

