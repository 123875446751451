export const formItem = [
	{
		type: 'input',
		label:'编号',
		prop:'code',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'名称',
		prop:'name',
		placeholder:'请输入'
	},
]
export const columns = [
	{
		title: "路线编号",
		dataIndex: "code",
	},
	{
		title: "路线名称",
		dataIndex: "name",
	},
	{
		title: "持续时间",
		dataIndex: "spaceTime",
	},
	{
		title: "创建时间",
		dataIndex: "createDate",
	},
	{
		title: "状态",
		dataIndex: "status",
		width: "6%",
		customRender: function (status) {
			switch (status) {
			case 1:
				return '启用'
			case 2:
				return '停用'
			}
		}
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}