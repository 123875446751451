export const form = {
	id:undefined,
	name:undefined,
	describes:undefined,
	status:undefined,
	spaceTime:undefined,
	pointRouteList:[]
}
export const rules = {
	name:[{required:true,message:'请输入路线名称',trigger:'blur'}],
	describes:[{required:true,message:'请输入描述',trigger:'blur'}],
	status:[{required:true,message:'请选择',trigger:'change'}],
	spaceTime:[{required:true,message:'请输入持续时间',trigger:'blur'}],
}
export const options = {
	status:[
		{ id:1, name:'启用' },
		{ id:2, name:'停用' },
	],
	pointList:[]
}