<template>
  <div>
    <a-drawer
      :title="title"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
			<a-form-model-item prop='name' label='路线名称'>
				<a-input v-model="form.name" placeholder="请输入" style="width:60%"></a-input>
			</a-form-model-item>
			<a-form-model-item prop='describes' label='描述'>
				<a-input v-model="form.describes" placeholder="请输入" style="width:60%"></a-input>
			</a-form-model-item>
			<a-form-model-item prop='status' label='状态' v-if="title==='新增巡检路线'">
				<a-select v-model="form.status" placeholder="请输入" style="width:60%">
					<a-select-option v-for="item in options.status" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
				</a-select>
			</a-form-model-item>
			<a-form-model-item prop='spaceTime' label='持续时间'>
				<a-input-number v-model="form.spaceTime" placeholder="请输入" style="width:60%"></a-input-number>
			</a-form-model-item>
			<a-form-model-item prop='pointRouteList' label='巡检路线'>
				<a-select mode="multiple" v-model="selectPoint" placeholder="请选择" style="width:60%">
					<a-select-option v-for="item in options.pointList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
				</a-select>
			</a-form-model-item>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { form, rules, options } from "./form.js";
import {
	routeInsert,
	routeUpdate,
	routeInfo,
	pointSelect
} from "@/api/operation/inspection/route.js";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		editId: Number,
	},
	data() {
		return {
			title: "新增巡检路线",
			form,
			rules,
			options,
			selectPoint:[]
		};
	},
	mounted() {
		this.getPoint();
	},
	methods: {
		async getPoint() {
			let res = await pointSelect();
			this.options.pointList = res.data;
		},
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.form.pointRouteList = [];
			this.selectPoint = []
			this.$emit("addClose");
		},
		success() {
			this.$emit("success");
			this.addClose();
		},
		submit() {
			this.form.pointRouteList = []
			for(let k in this.selectPoint){
				this.form.pointRouteList.push({inspectionPointId:this.selectPoint[k]});
			}
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if (this.editId === null) {
						let res = await routeInsert(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					} else {
						let res = await routeUpdate(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					}
				}
			});
		},
	},
	watch: {
		editId: {
			handler(val) {
				if (val !== null) {
					this.title = "修改巡检路线";
					this.selectPoint = []
					this.form.id = val;
					routeInfo({ routeId: val }).then((res) => {
						this.form = res.data;
						if(this.form.routePointList.length>0){
							for(let item of this.form.routePointList){
								this.selectPoint.push(item.id)
							}
						}
					});
				} else {
					this.title = "新增巡检路线";
				}
			},
			immediate: true,
		},
	},
};
</script>

<style></style>
